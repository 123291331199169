import { FlexContainer } from "./flexBox"

export function TechStack() {
    let icon_size = 32;
    let icon = (name, src) => {
        return (
            <>
            <img height={icon_size} src={src} alt={name}></img><span> </span>
            </>
        )
    }
    let tool = () => {
        return (
            <>
                <p>
                    {icon("cpp", "pls/cpp_logo.svg")}
                    {icon("cmake", "pls/Cmake.svg")}
                    {icon("docker", "pls/docker-4.svg")}
                    ...
                </p>
            </>
        )
    }
    return (
        <div>
            <div>
                <h3>
                    Tools
                </h3>
                {tool()}
            </div>
            <div>
                <h3>
                    Stacks
                </h3>
                <FlexContainer center={false}>
                    <div className="card">
                        <h4>CLI Tools</h4>
                        <p>Design and implement tools for automating repetitive tasks, streamlining development workflows.</p>
                    </div>
                    <div className="card">
                        <h4>Native Applications</h4>
                        <p>Create highly customized applications for specific operating systems.</p>
                        <p>Examples include developing desktop applications using Win32 API and WinRT for Windows.</p>
                    </div>
                    <div className="card">
                        <h4>Web-based Applications</h4>
                        <p>Develop applications with limited features but high accessibility and ease of update, compatible across a wide range of devices.</p>
                    </div>
                    <div className="card">
                        <h4>Cross-platform Desktop Applications</h4>
                        <p>Leverage platform-agnostic graphics APIs to render user interfaces, ensuring consistent appearance and user experience across different platforms.</p>
                    </div>
                </FlexContainer>

            </div>
        </div>
    )
}