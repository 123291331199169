import './flexBox.css'
export function FlexContainer({ flex = true, center = true, wrap = true, children, direction}) {
    let classType = center ? "flex-center" : "";
    classType += " ";
    classType += flex ? "flex-container " : " ";
    classType += wrap ? "flex-wrap " : " ";
    return (
        <div className={classType}  style={{gap:5,direction:direction}}>
            {children}
        </div>
    )
}