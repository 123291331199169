import './App.css';
import { MyNav } from './myNav'
import { MyFooter } from './myFooter';

import { FlexContainer } from './flexBox';
import { TechStack } from "./techStack"
function App() {
  return (
    <div className="App">
      <header></header>
      <MyNav></MyNav>
      <h2>
        About me
      </h2>
      <h3>Education</h3>
      <ol>
        <li>
          Shenzhen University (SZU) 2018-2022, bachelor of biotechnology
        </li>
      </ol>
      <h3>Programming</h3>
      <p>
        熟悉 cmake, MSVC, gcc, clang, new standard of c++, package manager, git, bash shell, some of Linux distro, build and run services using docker. know program for windows/Linux/macos, write desktop app, web services, remote apis and some opengl, Vulkan app.
        <p>basic knowledge for html, js, Rust, python. English is not bad.
        </p>
        <p>
          freely watch lecturers without any subtitles.

        </p>
      </p>
      <main>
        <TechStack></TechStack>

        <div>
          <h3>
            Worked for
          </h3>
          <ol>
            <li>深圳市章鱼工场科技有限公司 <span>2022-07 - now</span></li>
          </ol>
        </div>

        <div>
          <h3>
            Project experience
          </h3>
          <ul>
            <li>
              <h4>
                Magic Pen Slicer
              </h4>
              <p>
                一个 3d 打印用的软件，用户通过此软件将模型转换为打印机所需要的格式 软件有 Windows 版本和 Mac OS 版本，已经上架到 Apple store.
              </p>
              <p>
                <a href='https://octopusworks.com/#/products/software'>Project link</a>
              </p>
            </li>
          </ul>
        </div>
      </main>
      <MyFooter />

    </div>
  );
}

export default App;
